import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import ActionButton from "../components/actionButton"

const Formulare = ({data}) => {
  return (
    <Layout title="Formulare & Dokumente" slogan="Wichtige Formulare & Dokumente zum Herunterladen" headerImage={data.image1.childImageSharp.fluid}>   
      <div>
        <ul style={{margin: '0'}}>
          <ActionButton type="icon-pdf" address="/download/Anmeldung.pdf" text="Anmeldeformular" />
          <ActionButton type="icon-pdf" address="/download/Merkblatt.pdf" text="Merkblatt" />
          <ActionButton type="icon-pdf" address="/download/Taxordnung.pdf" text="Taxordnung Zimmerpreise & Tarifübersicht" />
          <ActionButton type="icon-pdf" address="/download/Pflegefinanzierung.pdf" text="Anmeldung zur Pflegefinanzierung" />

          <hr style={{marginTop: '40px', marginBottom: '40px'}}/>
          <ActionButton type="icon-pdf" address="/download/BESA.pdf" text="Finanzierung des Heimaufenthaltes" />
          <ActionButton type="icon-pdf" address="/download/Entscheidungsfindung-Verlegung-ins-Pflegeheim.pdf" text="Entscheidungsfindung Verlegung ins Pflegeheim" />
          <p>Wenn die menschenwürdige Betreuung eines Bewohners / einer Bewohnerin im Altersheim Bannau nicht mehr gewährleistet werden kann, wird eine Verlegung, nach Absprache mit den Angehörigen, ins Pflegeheim eingeleitet.</p>
          
        </ul>
      </div>

    </Layout>
  ) 
}

export default Formulare

export const pageQuery = graphql`
  query {
    image1: file(sourceInstanceName: {eq: "assets"}, relativePath: { eq: "bannau-start-eingang.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1600) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    },
  }
`